export const addIdUser = (AddIdUser) => {
    return {
        type: "NILAI_ID_USER",
        payload: AddIdUser,
    };
};

export const addNamaUser = (AddNamaUser) => {
    return {
        type: "NILAI_NAMA_USER",
        payload: AddNamaUser,
    };
};

export const addUsernameUser = (AddUsernameUser) => {
    return {
        type: "NILAI_USERNAME_USER",
        payload: AddUsernameUser,
    };
};

export const addlevelUser = (AddlevelUser) => {
    return {
        type: "NILAI_LEVEL_USER",
        payload: AddlevelUser,
    };
};


