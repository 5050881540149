const data_id = {
  value_id: 0,
  value_id_pemilih: 0,
  value_id_pengguna: 0,
  value_id_tema: 'lightmode',
  value_id_kecamatan: 0,
  value_id_kelurahan: 0,
};

const IdValueReducer = (state = data_id, action) => {
  switch (action.type) {
    case "NILAI_ID":
      return {
        ...state,
        value_id: action.payload,
      };
    case "NILAI_ID_PEMILIH":
      return {
        ...state,
        value_id_pemilih: action.payload,
      };
    case "NILAI_ID_PENGGUNA":
      return {
        ...state,
        value_id_pengguna: action.payload,
      };
    case "NILAI_ID_TEMA":
      return {
        ...state,
        value_id_tema: action.payload,
      };
    case "NILAI_ID_KECAMATAN":
      return {
        ...state,
        value_id_kecamatan: action.payload,
      };
    case "NILAI_ID_KELURAHAN":
      return {
        ...state,
        value_id_kelurahan: action.payload,
      };
    default:
      return state;
  }
};

export default IdValueReducer;
