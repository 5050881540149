import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { api } from "../../api";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { add_token } from "../../../Redux/Action/Tokenaction";

function PenggunaEdit() {
  const api = process.env.REACT_APP_BASE_URL;
  const getid = useSelector((state) => state.IdValueReducer);
  const getlink = useSelector((state) => state.LinkValueReducer);
  const get_token = useSelector((state) => state.TokenReducer);
  const get_user = useSelector((state) => state.UserReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = get_token.value_token;
  const [id_pengguna, setId_Pengguna] = useState("");
  const [nama_pengguna, setNama_Pengguna] = useState("");
  const [email_pengguna, setEmail] = useState("");
  const [level, setLevel] = useState("");
  const [username, setUsername] = useState("");
  const [no_hp, setNo_hp] = useState("");
  const [errorName, setErorrName] = useState(false);
  const [errorMessage, setErorMessage] = useState(false);
  // const [status, setStatus] = useState("");

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const response = await axios.get(
          `${api}/penggunaprofile/${getid.value_id_pengguna}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setId_Pengguna(response.data.pengguna.id);
        setNama_Pengguna(response.data.pengguna.name);
        setEmail(response.data.pengguna.email);
        setUsername(response.data.pengguna.username);
        setNo_hp(response.data.pengguna.no_hp);
        setLevel(response.data.pengguna.level);
      } catch (error) {
        console.log(error);
        if (error.response.data.message === "Unauthenticated.") {
          dispatch(add_token(""));
          navigate("/login");
        }
      }
    };
    fetchdata();
  }, [token, navigate, dispatch, getid.value_id_pengguna, api]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let data = JSON.stringify({
        name: nama_pengguna,
        email: email_pengguna,
        username: username,
        no_hp: no_hp,
        level: level,
      });
      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${api}/pengguna/${id_pengguna}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      const response = await axios.request(config);
      console.log(response.data.data.updated_at);
      Swal.fire({
        icon: "success",
        text: "Data berhasil diubah",
        title: "success",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        navigate(getlink.value_link_pengguna);
      });
    } catch (error) {
      console.log(error);
      if (error.response.data.message === "Unauthenticated.") {
        dispatch(add_token(""));
        navigate("/login");
      }
      setErorrName(error.response.data.name);
      setErorMessage(error.response.data.error);
    }
  };

  const handleDelete = async (id_delete) => {
    try {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${api}/pengguna/${id_delete}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.request(config);
      console.log(response.data);
      Swal.fire({
        icon: "success",
        text: "Data berhasil dihapus",
        title: "success",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        navigate(getlink.value_link_pengguna);
      });
    } catch (error) {
      console.log(error.response);
      setErorrName(error.response.data.error.name);
      setErorMessage(error.response.data.error.message);
      if (error.response.data.message === "Unauthenticated.") {
        dispatch(add_token(""));
        navigate("/login");
      }
    }
  };

  return (
    <>
      <div className="appHeader bg-danger text-light">
        <div className="left">
          <Link
            to={getlink.value_link_pengguna}
            className="headerButton goBack"
          >
            <ion-icon name="chevron-back-outline"></ion-icon>
          </Link>
        </div>
        {get_user.value_AddlevelUser === "Admin" ? (
          <div
            className="right"
            onClick={() => {
              handleDelete(id_pengguna);
            }}
            style={{ cursor: "pointer" }}
          >
            <ion-icon name="trash-outline" />
          </div>
        ) : (
          ""
        )}
        <div className="pageTitle">Profile</div>
      </div>

      <div className="d-flex justify-content-center mt-5 pt-5">
        <div className="card col-lg-6 col-md-12">
          <div className="card-body">
            <div className="col-12">
              <form className="row" onSubmit={handleSubmit}>
                <div className="form-group col-md-12">
                  <label className="form-label">Nama Lengkap</label>
                  <input
                    onChange={(event) => setNama_Pengguna(event.target.value)}
                    type="text"
                    className="form-control"
                    value={nama_pengguna}
                  />
                  {errorName === "name" && (
                    <div className="alert alert-danger mt-1" role="alert">
                      {errorMessage} !!!
                    </div>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label className="form-label">Email</label>
                  <input
                    onChange={(event) => setEmail(event.target.value)}
                    type="email"
                    className="form-control"
                    value={email_pengguna}
                  />
                  {errorName === "email" && (
                    <div className="alert alert-danger mt-1" role="alert">
                      {errorMessage} !!!
                    </div>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label className="form-label">Username</label>
                  <input
                    onChange={(event) => setUsername(event.target.value)}
                    type="text"
                    className="form-control"
                    value={username}
                  />
                  {errorName === "username" && (
                    <div className="alert alert-danger mt-1" role="alert">
                      {errorMessage} !!!
                    </div>
                  )}
                </div>
                {get_user.value_AddlevelUser !== "Admin" ? (
                  <div className="form-group col-md-6">
                    <label className="form-label">Level</label>
                    <input
                      type="text"
                      className="form-control"
                      value={level}
                      readOnly
                    />
                  </div>
                ) : (
                  <div className="form-group col-md-6">
                    <div className="input-wrapper">
                      <label className="form-label" htmlFor="Level">
                        Level
                      </label>
                      <select
                        className="form-control custom-select"
                        id="Level"
                        onChange={(event) => {
                          setLevel(event.target.value);
                        }}
                      >
                        <option value={level}>{level}</option>
                        <option value="Admin">Admin</option>
                        <option value="Operator">Operator</option>
                      </select>
                      {errorName === "level" ? (
                        <div className="text-danger">{errorMessage}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}

                <div className="form-group col-md-6">
                  <label className="form-label">Kontak</label>
                  <input
                    onChange={(event) => setNo_hp(event.target.value)}
                    type="number"
                    className="form-control"
                    value={no_hp}
                  />
                  {errorName === "no_hp" && (
                    <div className="alert alert-danger mt-1" role="alert">
                      {errorMessage} !!!
                    </div>
                  )}
                </div>
                {get_user.value_AddlevelUser === "Admin" ? (
                  <div className="col-12 d-flex justify-content-end">
                    <button type="submit" className="btn btn-outline-danger">
                      Simpan
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PenggunaEdit;
