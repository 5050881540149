const data_id = {
    value_link_pemilih: '',
    value_link_pengguna: '',
    value_tema: ''
};

const LinkValueReducer = (state = data_id, action) => {
    switch (action.type) {
        case "NILAI_LINK_PEMILIH":
            return {
                ...state,
                value_link_pemilih: action.payload,
            };
        case "NILAI_LINK_PENGGUNA":
            return {
                ...state,
                value_link_pengguna: action.payload,
            };
        case "NILAI_TEMA":
            return {
                ...state,
                value_tema: action.payload,
            };
        default:
            return state;
    }
};

export default LinkValueReducer;
