import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { add_token } from "../../../Redux/Action/Tokenaction";
import { add_id_pemilih } from "../../../Redux/Action/Idaction";
import { add_link_pemilih } from "../../../Redux/Action/LinkAction";

const Datapemilih = () => {
  const api = process.env.REACT_APP_BASE_URL;
  const get_token = useSelector((state) => state.TokenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = get_token.value_token;
  const [loading1, setLoading1] = useState(false);
  const [dataPemilih, setDataPemilih] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [kategori, setkategori] = useState("pemilih");
  const [search, setSearch] = useState([]);

  useEffect(() => {
    setLoading1(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/${kategori}?page=${currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(response.data)
        setLoading1(false);
        setDataPemilih(response.data.data);
        setTotalData(response.data.totaldata);
        setTotalPage(response.data.data.last_page);
        setCurrentPage(response.data.data.current_page);
      } catch (error) {
        console.log(error);
        if (error.response.data.message === "Unauthenticated.") {
          dispatch(add_token(""));
          navigate("/login");
        }
      }
    };
    fetchData();
  }, [token, currentPage, navigate, dispatch, api, kategori]);

  //pengaturan pagination
  let pages = [];
  if (totalPage <= 3) {
    pages = Array.from({ length: totalPage }, (_, index) => index + 1);
  } else if (currentPage <= 2) {
    pages = [1, 2, 3];
  } else if (currentPage >= totalPage - 1) {
    pages = [totalPage - 2, totalPage - 1, totalPage];
  } else {
    pages = [currentPage - 1, currentPage, currentPage + 1];
  }

  const fetchSearch = async (search) => {
    setLoading1(true);
    try {
      const response = await axios.get(
        `${api}/pemilih/search?query=${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      setLoading1(false);
      setDataPemilih(response.data.data);
      setTotalData(response.data.totaldata);
      setTotalPage(response.data.data.last_page);
      setCurrentPage(response.data.data.current_page);
    } catch (error) {
      console.log(error);
      if (error.response.data.message === "Unauthenticated.") {
        dispatch(add_token(""));
        navigate("/login");
      }
    }
  };

  return (
    <>
      <div className="mb-5">
        <div>
          <div className="appHeader bg-danger text-light">
            <div className="left">
              <Link to="/" className="headerButton goBack">
                <ion-icon name="chevron-back-outline" />
              </Link>
            </div>
            <div className="pageTitle">Datar Hadir</div>
            <div
              className="right"
              style={{ cursor: "pointer" }}
              // onClick={handleLogout}
            >
              <Link to="/profile" className="headerButton goBack">
                <ion-icon name="person-outline" />
              </Link>
            </div>
          </div>
          <div id="appCapsule">
            <div className="listview-title mt-2">Cari Nama</div>
            <ul className="listview simple-listview">
              <li>
                <form className="search-form">
                  <div className="form-group searchbox">
                    <input
                      onChange={(event) => {
                        setSearch(event.target.value);
                      }}
                      type="text"
                      className="form-control"
                      placeholder="Cari peserta.."
                    />
                    <i className="input-icon">
                      <ion-icon name="search-outline" />
                    </i>
                    <div
                      className="ml-1"
                      onClick={() => {
                        fetchSearch(search);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <ion-icon name="search-outline" />
                    </div>
                  </div>
                </form>
              </li>
            </ul>
            <div
              className="listview-title pt-2 pb-0"
              style={{ fontSize: "14px" }}
            ></div>
            <div className="rekappresence pl-1 pr-1">
              <div className="row">
                <div
                  className="col-12"
                  onClick={() => {
                    setkategori("pemilih");
                  }}
                >
                  <div className="card">
                    <div className="card-body" style={{ cursor: "pointer" }}>
                      <div className="presencecontent text-center">
                        <div className="presencedetail">
                          <h5 className="rekappresencetitle">Jumlah Peserta</h5>
                          <span className="badge badge-danger">
                            {totalData}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-4"
                  onClick={() => {
                    setkategori("pemilih/sudahpasti");
                  }}
                ></div>
              </div>
            </div>
            <div
              className="listview-title pt-2 pb-0"
              style={{ fontSize: "14px" }}
            >
              Jumlah Peserta
            </div>
            {loading1 ? (
              <div className="text-center mt-5">
                <div className="spinner-border text-danger" role="status"></div>
              </div>
            ) : (
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <ul className="listview image-listview">
                      {dataPemilih.data &&
                        dataPemilih.data.map((datapemilih, index) => (
                          <li key={datapemilih.id}>
                            <div className="item">
                              {datapemilih.status === "sudah_pasti" ? (
                                <div className="icon-box bg-danger">
                                  {(currentPage - 1) * 10 + index + 1}
                                </div>
                              ) : (
                                <div className="icon-box bg-danger">
                                  {(currentPage - 1) * 10 + index + 1}
                                </div>
                              )}

                              <div className="in">
                                <div>
                                  {datapemilih.nama}
                                  <header>{datapemilih.nik}</header>
                                  <footer>{datapemilih.alamat}</footer>
                                </div>
                                <Link
                                  onClick={() => {
                                    dispatch(add_id_pemilih(datapemilih.id));
                                    dispatch(add_link_pemilih("/datapemilih"));
                                  }}
                                  to="/detailpemilih"
                                >
                                  <span className="badge badge-danger">
                                    Details
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ul className="pagination justify-content-center mb-3">
        <ul className="pagination">
          {currentPage > 2 && (
            <li className="page-item">
              <button className="page-link" onClick={() => setCurrentPage(1)}>
                First
              </button>
            </li>
          )}
          {currentPage > 2 && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                «
              </button>
            </li>
          )}
          {pages.map((page) => (
            <li
              key={page}
              className={
                page === currentPage ? "page-item active" : "page-item"
              }
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage(page)}
              >
                {page}
              </button>
            </li>
          ))}
          {currentPage < totalPage && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                »
              </button>
            </li>
          )}
          {currentPage < totalPage && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => setCurrentPage(totalPage)}
              >
                Last
              </button>
            </li>
          )}
        </ul>
      </ul>
    </>
  );
};

export default Datapemilih;
