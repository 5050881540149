import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { add_token } from "../../../Redux/Action/Tokenaction";
import { add_id_kecamatan } from "../../../Redux/Action/Idaction";

const Kecamatan = () => {
  const api = process.env.REACT_APP_BASE_URL;
  const get_token = useSelector((state) => state.TokenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = get_token.value_token;
  const [loading1, setLoading1] = useState(false);
  const [dataKecamatan, setDataKecamatan] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setLoading1(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/kecamatan?page=${currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLoading1(false);
        setDataKecamatan(response.data.data);
        setCurrentPage(response.data.data.current_page);
      } catch (error) {
        console.log(error);
        if (error.response.data.message === "Unauthenticated.") {
          dispatch(add_token(""));
          navigate("/login");
        }
      }
    };
    fetchData();
  }, [token, currentPage, navigate, dispatch, api]);

  const color = [
    "bg-primary",
    "bg-secondary",
    "bg-warning",
    "bg-danger",
    "bg-success",
    "bg-info",
    "bg-orange",
    "bg-pink",
    "bg-purple",
    "bg-green",
    "bg-yellow",
    "bg-cyan",
    "bg-brown",
    "bg-gold",
    "bg-lavender",
    "bg-magenta",
    "bg-teal",
  ];

  return (
    <div>
      {loading1 ? (
        <div id="loader">
          <div className="spinner-border text-danger" role="status"></div>
        </div>
      ) : (
        <>
          <div className="appHeader bg-danger text-light">
            <div className="left">
              <Link to="/" className="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
              </Link>
            </div>
            <div className="pageTitle">List Kecamatan</div>
            {/* <Link to='/tambahkecamatan' className="right">
                            <div className="headerButton toggle-searchbox">
                                <ion-icon name="duplicate-outline"></ion-icon>
                            </div>
                        </Link> */}
          </div>

          <div id="appCapsule">
            <div className="listview-title mt-2">Cari Data Kecamatan</div>
            {/* <ul className="listview simple-listview">
                            <li>
                                <form className="search-form">
                                    <div className="form-group searchbox">
                                        <input type="text" className="form-control" placeholder="Cari data kecamatan.." />
                                        <i className="input-icon">
                                            <ion-icon name="search-outline"></ion-icon>
                                        </i>
                                        <div className="ml-1">
                                            <ion-icon name="search-outline"></ion-icon>
                                        </div>
                                    </div>
                                </form>
                            </li>
                        </ul> */}
            <div>
              {/* <div className="listview-title mt-2 text-muted"><h3>Data Kecamatan</h3></div> */}
              <ul className="listview image-listview">
                {dataKecamatan.data &&
                  dataKecamatan.data.map((kecamatan, index) => (
                    <li key={kecamatan.id}>
                      <div className="item">
                        <div
                          className={`icon-box ${color[index % color.length]}`}
                        >
                          <ion-icon name="location" />
                        </div>
                        <div className="in">
                          <div>
                            {kecamatan.kecamatan}
                            <header>
                              <span className="badge badge-info">
                                Total : {kecamatan.c_pemilih_count}
                              </span>
                            </header>
                            <footer>
                              <span className="badge badge-danger">
                                {kecamatan.c_pemilih_pasti_count}
                              </span>
                              <span className="badge badge-warning">
                                {kecamatan.c_pemilih_belum_pasti_count}
                              </span>
                            </footer>
                          </div>
                          <Link
                            to="/detailkecamatan"
                            onClick={() => {
                              dispatch(add_id_kecamatan(kecamatan.id));
                            }}
                            className="text-dark"
                          >
                            Details
                          </Link>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Kecamatan;
