import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./components/contente/Dashboard";
import Profile from "./components/contente/pengguna/Profile";
import Pengguna from "./components/contente/pengguna/Pengguna";
import PenggunaAdd from "./components/contente/pengguna/Penggunaadd";
import PenggunaEdit from "./components/contente/pengguna/Penggunaedit";
import NotFount from "./components/contente/Notfound";
import Main from "./pages/Main";
import Lupaspass from "./pages/Lupaspass";
import Resetpass from "./components/contente/pengguna/Resetpass";
import ResetPassword from "./pages/Resetpassword";
import Kecamatan from "./components/contente/kecamatan/Kecamatan";
import { useSelector } from "react-redux";
import Datapemilih from "./components/contente/datapemilih/Datapemilih";
import TambahKecamatan from "./components/contente/kecamatan/TambahKecamatan";
import TambahPemilih from "./components/contente/datapemilih/TambahPemilih";
import DetailKecamatan from "./components/contente/kecamatan/DetailKecamatan";
import DataPemilihPengguna from "./components/contente/datapemilih/DataPemilihPengguna";
import DataKelurahan from "./components/contente/Kelurahan/DataKelurahan";
import DetailPemilih from "./components/contente/datapemilih/DetailPemilih";
import EditProfile from "./components/contente/pengguna/EditProfile";


function App() {
  const getTema = useSelector((state) => state.LinkValueReducer);
  return (
    <div className={getTema.value_tema}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/lupapass" element={<Lupaspass />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/" element={<Main />}>
            <Route path="/" element={<Dashboard />} />

            <Route path="/datapemilih" element={<Datapemilih />} />
            <Route path="/datapemilihpengguna" element={<DataPemilihPengguna />} />
            <Route path="/tambahpemilih" element={<TambahPemilih />} />
            <Route path="/detailpemilih" element={<DetailPemilih />} />

            <Route path="/kecamatan" element={<Kecamatan />} />
            <Route path="/tambahkecamatan" element={<TambahKecamatan />} />
            <Route path="/detailkecamatan" element={<DetailKecamatan />} />

            <Route path="/kelurahan" element={<DataKelurahan />} />

            <Route path="/profile" element={<Profile />} />
            <Route path="/pengguna" element={<Pengguna />} />
            <Route path="/tambahuser" element={<PenggunaAdd />} />
            <Route path="/edituser" element={<PenggunaEdit />} />
            <Route path="/editprofile" element={<EditProfile />} />

            <Route path="/resetpass" element={<Resetpass />} />
            <Route path="*" element={<NotFount />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;

