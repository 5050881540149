const data_token = {
  value_token: '',
};

const TokenReducer = (state = data_token, action) => {
  switch (action.type) {
    case "NILAI_TOKEN":
      return {
        ...state,
        value_token: action.payload,
      };
    default:
      return state;
  }
};

export default TokenReducer;
