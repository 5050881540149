import axios from 'axios';
import React from 'react'
import { useNavigate, Link } from "react-router-dom"
import { useState } from "react";
// import { api } from "../components/api";

// redux
import { useSelector } from "react-redux";
import { useEffect } from "react";

const Lupaspass = () => {
    const api = process.env.REACT_APP_BASE_URL;
    const get_token = useSelector((state) => state.TokenReducer);
    const token = get_token.value_token;
    const [email, setEmail] = useState("");
    const [errorName, setErorrName] = useState(false);
    const [errorMessage, setErorMessage] = useState(false);
    const [suksesstatus, setSuksesstatus] = useState(false)
    const [sukses, setSukses] = useState(false)
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();
        try {
            let data = JSON.stringify({
                'email': email,
            });
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${api}/auth/forgot-password`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            const response = await axios.request(config);
            setLoading(false)
            setSuksesstatus(response.status)
            setSukses(response.data.status)
        } catch (error) {
            console.log(error);
            // console.log(error.response.data.email);
            // console.log(error.response.status);
            //     navigate('/login');          
            setErorrName(error.response.status);
            if (error.response.status === 400) {
                setErorMessage(error.response.data.email)
            } else if (error.response.status === 422) {
                setErorMessage(error.response.data.message)
            } else { setErorMessage('kosong'); }

        }
    }

    useEffect(() => {
        if (token !== '') {
            navigate('/')
        }
    }, [navigate, token])

    return (
        <div className="wrapper pt-5">
            <div className="authentication-forgot d-flex align-items-center justify-content-center">
                <div className="card forgot-box">
                    <div className="card-body">
                        <div className="pl-2 pr-2 pb-2 rounded  border">
                            {/* <div className="text-center">
                                <img src="assets/images/icons/forgot-2.png" width={120} alt='lupaspass' />
                            </div> */}
                            <h4 className="mt-2 font-weight-bold">Lupa Password?</h4>
                            <p className="text-muted">Masukan alamat Email yang terdaftar.
                                <br />
                                Link Reset Password akan dikirim ke alamat email anda.
                            </p>
                            {loading ?
                                <div class="text-center">
                                    <div class="spinner-border m-5 text-secondary" role="status">
                                        <span class="visually-hidden"></span>
                                    </div>
                                </div> :
                                errorName === 400 ?
                                    <div className="alert alert-danger mt-1" role="alert">
                                        {errorMessage} !!!
                                    </div>
                                    : suksesstatus === 200 ? <div className="alert alert-success mt-1" role="alert">
                                        {sukses}
                                    </div> : errorName === 422 ? <div className="alert alert-danger mt-1" role="alert">
                                        {errorMessage} !!!
                                    </div> : ''
                            }

                            <form className="row" onSubmit={handleSubmit}>
                                <div className="my-1 col-12">
                                    <label className="form-label">Alamat Email</label>
                                    <input type="email" onChange={(event) => setEmail(event.target.value)} className="form-control form-control-lg" placeholder="emailanda@gmail.com" />
                                </div>
                                <div className="form-links col-12 mt-1">
                                    <Link to="/login" className="btn btn-secondary btn"><i className="bx bx-arrow-back me-1" />Kembali</Link>
                                    <button type="submit" className="btn btn-success btn">Kirim</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Lupaspass
