import { Link } from "react-router-dom";
import TambahPemilih from "../contente/datapemilih/TambahPemilih";

function Dashboard() {
  return (
    <>
      <div id="appCapsule">
        <div className="section bg-danger" id="user-section">
          <div id="user-detail">
            <div className="avatar">
              <img
                src="assets/img/sample/avatar/avatar1.jpg"
                alt="avatar"
                style={{ width: "80px", height: "80px" }}
              />
            </div>
            <div id="user-info">
              <h2 id="user-name">PRB</h2>
              <h3>
                <span id="user-role">Pejuang Rakyat Bitung</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="section" id="presence-section">
          <Link to="/datapemilih" className="rekappresence pl-1 pr-1">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body" style={{ cursor: "pointer" }}>
                    <div className="presencecontent text-center">
                      <div className="presencedetail">
                        <h2 className="rekappresencetitle">
                          Lihat daftar hadir
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <div>
            <h2>Isi daftar hadir</h2>
            <TambahPemilih />
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
