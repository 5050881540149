import { Link } from "react-router-dom";
import React, { useState } from "react";
import Progress from "./Progress";
import Anggota from "./Anggota";

function Pengguna() {
  const [loadDataAnggota, setLoadDataAnggota] = useState(true);
  return (
    <>
      <div className="appHeader bg-danger text-light">
        <div className="left">
          <Link to="/" className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </Link>
        </div>
        <div className="pageTitle">List Data Anggota</div>
      </div>

      <div id="appCapsule">
        <div className="p-1 pt-5 mt-5">
          <div className="row">
            <div className="col-6">
              <div
                className="card text-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setLoadDataAnggota(true);
                }}
              >
                List Anggota
              </div>
            </div>
            <div className="col-6">
              <div
                className="card text-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setLoadDataAnggota(false);
                }}
              >
                Progress Anggota
              </div>
            </div>
          </div>
        </div>
        <div>{loadDataAnggota ? <Anggota /> : <Progress />}</div>
      </div>
    </>
  );
}

export default Pengguna;
