import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { api } from "../../api";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { add_token } from "../../../Redux/Action/Tokenaction";

const Resetpass = () => {
  const api = process.env.REACT_APP_BASE_URL;
  const getid = useSelector((state) => state.IdValueReducer);
  const get_token = useSelector((state) => state.TokenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = get_token.value_token;

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retryPassword, setRetryPassword] = useState("");
  const [errorName, setErorrName] = useState(false);
  const [errorMessage, setErorMessage] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let data = JSON.stringify({
        password_lama: password,
        password_baru: newPassword,
        password_konfirmasi: retryPassword,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${api}/auth/resetpassword/${getid.value_id_pengguna}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      const response = await axios.request(config);
      console.log(response.data);
      Swal.fire({
        icon: "success",
        text: "Data berhasil diubah",
        title: "success",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        navigate("/profile");
      });
    } catch (error) {
      console.log(error.response.data.error);
      if (error.response.data.message === "Unauthenticated.") {
        dispatch(add_token(""));
        navigate("/login");
      }
      setErorrName(error.response.data.error.name);
      setErorMessage(error.response.data.error.error);
    }
  };

  return (
    <>
      <div className="appHeader bg-danger text-light">
        <div className="left">
          <Link to="/" className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </Link>
        </div>
        <div className="pageTitle">Ganti Password</div>
      </div>

      <div className="wrapper mt-5 pt-5">
        <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
          <div className="container-fluid">
            <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
              <div className="col-md-6 col-lg-6 mx-auto">
                <div className="card">
                  <div className="row g-0">
                    <div className="card-body">
                      <form className="row" onSubmit={handleSubmit}>
                        <div className="col-12">
                          <label
                            htmlFor="inputChoosePassword"
                            className="form-label"
                          >
                            Password Lama
                          </label>
                          <div className="input-group" id="show_hide_password">
                            <input
                              onChange={(event) =>
                                setPassword(event.target.value)
                              }
                              id="password_lama"
                              name="password_lama"
                              type="password"
                              className="form-control border-start-0 border-end-0"
                              placeholder="Masukan Password Lama"
                            />
                          </div>
                          {errorName === "password_lama" && (
                            <div
                              className="alert alert-danger mt-1"
                              role="alert"
                            >
                              {errorMessage} !!!
                            </div>
                          )}
                        </div>
                        <div className="col-12  mt-3">
                          <label
                            htmlFor="inputChoosePassword"
                            className="form-label"
                          >
                            Password Baru
                          </label>
                          <div className="input-group" id="show_hide_password">
                            <input
                              onChange={(event) =>
                                setNewPassword(event.target.value)
                              }
                              id="password_baru"
                              name="password_baru"
                              type="password"
                              className="form-control border-start-0 border-end-0"
                              placeholder="Masukan Password Baru"
                            />
                          </div>
                          {errorName === "password_baru" && (
                            <div
                              className="alert alert-danger mt-1"
                              role="alert"
                            >
                              {errorMessage} !!!
                            </div>
                          )}
                        </div>
                        <div className="col-12  mt-3">
                          <label
                            htmlFor="inputChoosePassword"
                            className="form-label"
                          >
                            Konfirmasi Password Baru
                          </label>
                          <div className="input-group" id="show_hide_password">
                            <input
                              onChange={(event) =>
                                setRetryPassword(event.target.value)
                              }
                              id="password_konfirmasi"
                              name="password_konfirmasi"
                              type="password"
                              className="form-control border-start-0 border-end-0"
                              placeholder="Konfirmasi Password Baru"
                            />
                          </div>
                          {errorName === "password_konfirmasi" && (
                            <div
                              className="alert alert-danger mt-1"
                              role="alert"
                            >
                              {errorMessage} !!!
                            </div>
                          )}
                        </div>
                        <div className="form-links col-12 mt-3">
                          <Link to="/profile" className="btn btn-secondary">
                            <i className="bx bx-arrow-back mr-1" />
                            Kembali ke Profile
                          </Link>
                          <button type="submit" className="btn btn-danger">
                            Ubah Password
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resetpass;
