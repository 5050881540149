export const AddEmail = (AddEmail) => {
  return {
    type: "NILAI_EMAIL",
    payload: AddEmail,
  };
};

export const AddPassword = (AddPassword) => {
  return {
    type: "NILAI_PASSWORD",
    payload: AddPassword,
  };
};
