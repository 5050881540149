import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { add_token } from "../../../Redux/Action/Tokenaction";

const TambahPemilih = () => {
  const api = process.env.REACT_APP_BASE_URL;
  const get_token = useSelector((state) => state.TokenReducer);
  // const get_user = useSelector((state) => state.UserReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = get_token.value_token;
  const [nama, setNama] = useState("");
  const [nik, setNik] = useState("");
  const [alamat, setAlamat] = useState("");
  const [jk, setJk] = useState("");
  const [id_kecamatan, setId_kecamatan] = useState("");
  const [id_kelurahan, setId_kelurahan] = useState("");
  const [errorName, setErorrName] = useState(false);
  const [errorMessage, setErorMessage] = useState(false);
  const [dataKecamatan, setDataKecamatan] = useState(false);
  const [dataKelurahan, setDataKelurahan] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const fetchDataKel = async (idkec) => {
    try {
      const response = await axios.get(
        `${api}/kelurahan/option?query=${idkec}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDataKelurahan(response.data.data);
      console.log(response.data.data.length);
    } catch (error) {
      console.log(error);
      if (error.response.data.message === "Unauthenticated.") {
        dispatch(add_token(""));
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    setLoading1(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(`${api}/kecamatan/option`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setLoading1(false);
        setDataKecamatan(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.log(error);
        if (error.response.data.message === "Unauthenticated.") {
          dispatch(add_token(""));
          navigate("/login");
        }
      }
    };
    fetchData();
  }, [token, navigate, dispatch, api]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let data = JSON.stringify({
        nama: nama,
        nik: nik,
        alamat: alamat,
        jk: jk,
        id_kecamatan: id_kecamatan,
        id_kelurahan: id_kelurahan,
        id_user: 1,
        status: "sudah_pasti",
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${api}/pemilih`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      const response = await axios.request(config);
      console.log(response.data);
      Swal.fire({
        icon: "success",
        text: "Data berhasil disimpan",
        title: "success",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        navigate("/datapemilih");
      });
    } catch (error) {
      console.log(error.response);
      setErorrName(error.response.data.error.name);
      setErorMessage(error.response.data.error.message);
      if (error.response.data.message === "Unauthenticated.") {
        dispatch(add_token(""));
        navigate("/login");
      }
    }
  };

  return (
    <>
      {loading1 ? (
        <div id="loader">
          <div className="spinner-border text-danger" role="status"></div>
        </div>
      ) : (
        <div>
          <div id="appCapsule">
            <div className="section full mb-2">
              <div className="pt-2 pb-2">
                <form
                  className="needs-validation col-md-6 mx-auto"
                  onSubmit={handleSubmit}
                  noValidate
                >
                  <div className="form-group boxed">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="name5">
                        Nama Lengkap
                      </label>
                      <input
                        onChange={(event) => setNama(event.target.value)}
                        type="text"
                        className="form-control"
                        id="name5"
                        placeholder="Masukan nama sesuai KTP"
                        required
                      />
                      {errorName === "nama" ? (
                        <div className="text-danger">{errorMessage}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group boxed">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="name5">
                        NIK
                      </label>
                      <input
                        onChange={(event) => setNik(event.target.value)}
                        type="number"
                        className="form-control"
                        id="name5"
                        placeholder="Masukan NIK KTP"
                        required
                      />
                      {errorName === "nik" ? (
                        <div className="text-danger">{errorMessage}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group boxed">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="jk">
                        Jenis Kelamin
                      </label>
                      <select
                        className="form-control custom-select"
                        id="jk"
                        value={jk}
                        onChange={(event) => {
                          setJk(event.target.value);
                        }}
                      >
                        <option value="">Pilih Jenis Kelamin...</option>
                        <option value="laki-laki">Laki-laki</option>
                        <option value="perempuan">Perempuan</option>
                      </select>
                      {errorName === "jk" ? (
                        <div className="text-danger">{errorMessage}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group boxed">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="kecamatan">
                        Kecamatan
                      </label>
                      <select
                        className="form-control custom-select"
                        id="kecamatan"
                        value={id_kecamatan}
                        onChange={(event) => {
                          setId_kecamatan(event.target.value);
                          fetchDataKel(event.target.value);
                        }}
                      >
                        <option value="">Pilih Kecamatan...</option>
                        {dataKecamatan &&
                          dataKecamatan.map((kecamatan) => (
                            <option value={kecamatan.id} key={kecamatan.id}>
                              {kecamatan.kecamatan}
                            </option>
                          ))}
                      </select>
                      {errorName === "id_kecamatan" ? (
                        <div className="text-danger">{errorMessage}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group boxed">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="kelurahan">
                        Kelurahan
                      </label>
                      <select
                        className="form-control custom-select"
                        id="kelurahan"
                        value={id_kelurahan}
                        onChange={(event) => {
                          setId_kelurahan(event.target.value);
                        }}
                      >
                        <option value="">Pilih Kelurahan...</option>
                        {dataKelurahan &&
                          dataKelurahan.map((kelurahan) => (
                            <option value={kelurahan.id} key={kelurahan.id}>
                              {kelurahan.kelurahan}
                            </option>
                          ))}
                      </select>
                      {errorName === "id_kelurahan" ? (
                        <div className="text-danger">{errorMessage}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group boxed">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="name5">
                        Alamat
                      </label>
                      <input
                        onChange={(event) => setAlamat(event.target.value)}
                        type="text"
                        className="form-control"
                        id="name5"
                        placeholder="Masukan alamat"
                        required
                      />
                      {errorName === "alamat" ? (
                        <div className="text-danger">{errorMessage}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="mt-2">
                    <button className="btn btn-danger btn-block" type="submit">
                      Simpan
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TambahPemilih;
