import { Link } from "react-router-dom";
function NotFount() {
    return (
        <>
            <section className="content">
                <div className="error-page">
                    <div className="container">
                        <div className="card">
                            <div className="row g-0">
                                <div className="col col-xl-5">
                                    <div className="card-body p-4">
                                        <h1 className="display-1"><span className="text-primary">4</span><span className="text-danger">0</span><span className="text-success">4</span></h1>
                                        <h2 className="font-weight-bold display-4">Lost in space</h2>
                                        <p> Halaman yang Anda minta tidak dapat ditemukan.
                                            <br />Jangan khawatir dan kembali ke halaman utama.

                                        </p>
                                        <div className="mt-5"> <Link to="/" className="btn btn-danger btn-lg px-md-5 radius-30">Kembali</Link>
                                            {/* <a href="/" className="btn btn-outline-dark btn-lg ms-3 px-md-5 radius-30">Back</a> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-7">
                                    <img src="https://cdn.searchenginejournal.com/wp-content/uploads/2019/03/shutterstock_1338315902.png" className="img-fluid" alt="imgprofile" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default NotFount;