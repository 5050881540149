import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { add_token } from "../../../Redux/Action/Tokenaction";
import { add_link_pemilih } from "../../../Redux/Action/LinkAction";
import { add_id_pemilih } from "../../../Redux/Action/Idaction";

const DataPemilihPengguna = () => {
  const api = process.env.REACT_APP_BASE_URL;
  const get_token = useSelector((state) => state.TokenReducer);
  const get_pengguna = useSelector((state) => state.IdValueReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = get_token.value_token;
  const [dataPemilih, setDataPemilih] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalPasti, setTotalPasti] = useState("");
  const [TotalBelumPasti, setTotalBelumpasti] = useState("");
  const [PenggunaData, setPenggunaData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getLink, setGetLink] = useState("pengguna");
  const [search, setSearch] = useState([]);

  //pengaturan pagination
  let pages = [];
  if (totalPage <= 3) {
    pages = Array.from({ length: totalPage }, (_, index) => index + 1);
  } else if (currentPage <= 2) {
    pages = [1, 2, 3];
  } else if (currentPage >= totalPage - 1) {
    pages = [totalPage - 2, totalPage - 1, totalPage];
  } else {
    pages = [currentPage - 1, currentPage, currentPage + 1];
  }

  useEffect(() => {
    if (get_pengguna.value_id_pengguna !== 0) {
      setLoading(true);
      const fetchdata1 = async () => {
        try {
          let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${api}/${getLink}/${get_pengguna.value_id_pengguna}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await axios.request(config);
          setLoading(false);
          setTotalData(response.data.jumlah);
          setTotalPasti(response.data.pasti);
          setTotalBelumpasti(response.data.belum);
          setDataPemilih(response.data.data);
          setTotalPage(response.data.data.last_page);
          setPenggunaData(response.data.pengguna.username);
        } catch (error) {
          console.log(error);
          if (error.response.data.message === "Unauthenticated.") {
            dispatch(add_token(""));
            navigate("/login");
          }
        }
      };
      fetchdata1();
    } else {
      <></>;
    }
  }, [token, api, dispatch, get_pengguna.value_id_pengguna, navigate, getLink]);

  const fetchSearch = async (search) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${api}/pemilih/searchpengguna?query=${search}&pengguna=${get_pengguna.value_id_pengguna}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      setLoading(false);
      setDataPemilih(response.data.data);
      setTotalData(response.data.totaldata);
      setTotalPasti(response.data.totaldataPasti);
      setTotalBelumpasti(response.data.totaldataBelumPasti);
      setTotalPage(response.data.data.last_page);
      setCurrentPage(response.data.data.current_page);
    } catch (error) {
      console.log(error);
      if (error.response.data.message === "Unauthenticated.") {
        dispatch(add_token(""));
        navigate("/login");
      }
    }
  };

  return (
    <>
      <div className="mb-5">
        {loading ? (
          <div id="loader">
            <div className="spinner-border text-danger" role="status"></div>
          </div>
        ) : (
          <div>
            <div className="appHeader bg-danger text-light">
              <div className="left">
                <Link to="/" className="headerButton goBack">
                  <ion-icon name="chevron-back-outline" />
                </Link>
              </div>
              <div className="pageTitle">List Pemilih {PenggunaData}</div>
              <div className="right">
                <Link to="/tambahpemilih" className="headerButton goBack">
                  <ion-icon name="person-add-outline" />
                </Link>
              </div>
            </div>
            <div id="appCapsule">
              <div className="listview-title mt-2">Cari Data Pemilih</div>
              <ul className="listview simple-listview">
                <li>
                  <form className="search-form">
                    <div className="form-group searchbox">
                      <input
                        onChange={(event) => {
                          setSearch(event.target.value);
                        }}
                        type="text"
                        className="form-control"
                        placeholder="Cari data pemilih.."
                      />
                      <i className="input-icon">
                        <ion-icon name="search-outline" />
                      </i>
                      <div
                        className="ml-1"
                        onClick={() => {
                          fetchSearch(search);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <ion-icon name="search-outline" />
                      </div>
                    </div>
                  </form>
                </li>
              </ul>

              <div
                className="listview-title pt-2 pb-0"
                style={{ fontSize: "14px" }}
              >
                Jumlah Pemilih
              </div>

              <div className="rekappresence pl-1 pr-1">
                <div className="row">
                  <div
                    className="col-4"
                    onClick={() => {
                      setGetLink("pengguna");
                    }}
                  >
                    <div className="card">
                      <div className="card-body" style={{ cursor: "pointer" }}>
                        <div className="presencecontent text-center">
                          <div className="presencedetail">
                            <h5 className="rekappresencetitle">Total</h5>
                            <span className="badge badge-info">
                              {totalData}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-4"
                    onClick={() => {
                      setGetLink("penggunaaktif");
                    }}
                  >
                    <div className="card">
                      <div className="card-body" style={{ cursor: "pointer" }}>
                        <div className="presencecontent text-center">
                          <div className="presencedetail">
                            <h5 className="rekappresencetitle">Pasti</h5>
                            <span className="badge badge-danger">
                              {TotalPasti}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-4"
                    onClick={() => {
                      setGetLink("penggunabelum");
                    }}
                  >
                    <div className="card">
                      <div className="card-body" style={{ cursor: "pointer" }}>
                        <div className="presencecontent text-center">
                          <div className="presencedetail">
                            <h5 className="rekappresencetitle">Belum</h5>
                            <span className="badge badge-warning">
                              {TotalBelumPasti}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="listview-title pt-2 pb-0"
                style={{ fontSize: "14px" }}
              >
                List Data Pemilih
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <ul className="listview image-listview">
                      {dataPemilih.data &&
                        dataPemilih.data.map((datapemilih, index) => (
                          <li key={datapemilih.id}>
                            <div className="item">
                              {datapemilih.status === "sudah_pasti" ? (
                                <div className="icon-box bg-danger">
                                  <ion-icon name="checkmark-outline" />
                                </div>
                              ) : (
                                <div className="icon-box bg-warning">
                                  <ion-icon name="alert-outline" />
                                </div>
                              )}

                              <div className="in">
                                <div>
                                  {datapemilih.nama}
                                  <header>{datapemilih.nik}</header>
                                  <footer>{datapemilih.alamat}</footer>
                                </div>
                                <Link
                                  onClick={() => {
                                    dispatch(add_id_pemilih(datapemilih.id));
                                    dispatch(
                                      add_link_pemilih("/datapemilihpengguna")
                                    );
                                  }}
                                  to="/detailpemilih"
                                >
                                  <span className="badge badge-info">
                                    Details
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ul className="pagination justify-content-center mb-3">
        <ul className="pagination">
          {currentPage > 2 && (
            <li className="page-item">
              <button className="page-link" onClick={() => setCurrentPage(1)}>
                First
              </button>
            </li>
          )}
          {currentPage > 2 && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                «
              </button>
            </li>
          )}
          {pages.map((page) => (
            <li
              key={page}
              className={
                page === currentPage ? "page-item active" : "page-item"
              }
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage(page)}
              >
                {page}
              </button>
            </li>
          ))}
          {currentPage < totalPage && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                »
              </button>
            </li>
          )}
          {currentPage < totalPage && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => setCurrentPage(totalPage)}
              >
                Last
              </button>
            </li>
          )}
        </ul>
      </ul>
    </>
  );
};

export default DataPemilihPengguna;
