import { useNavigate, Link } from "react-router-dom";
import React, { useState, useCallback } from "react";
import { useEffect } from "react";
import axios from "axios";

// redux
import { useDispatch, useSelector } from "react-redux";
import { add_token } from "../Redux/Action/Tokenaction";
import { AddEmail, AddPassword } from "../Redux/Action/PrivateAction";

function Login() {
  const dispatch = useDispatch();
  const api = process.env.REACT_APP_BASE_URL;
  const get_token = useSelector((state) => state.TokenReducer);
  const token = get_token.value_token;
  const get_private = useSelector((state) => state.PrivateReducer);
  const [showPassword, setShowPassword] = useState(false);
  const [iconPassword, setIconPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
    setIconPassword((prevState) => !prevState);
  };
  const [alerts, setAlerts] = useState(false);
  const [email, setEmail] = useState(get_private.value_AddEmail);
  const [password, setPassword] = useState(get_private.value_AddPassword);
  const navigate = useNavigate();
  const [loading1, setLoading1] = useState(true);

  const handleAutoSubmit = useCallback(async () => {
    try {
      let data = JSON.stringify({
        email: get_private.value_AddEmail,
        password: get_private.value_AddPassword,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${api}/auth/login`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      const response = await axios.request(config);
      setLoading1(false);
      dispatch(add_token(response.data.access_token));
      dispatch(AddEmail(email));
      dispatch(AddPassword(password));
      navigate("/");
    } catch (error) {
      setLoading1(false);
      navigate("/login");
      console.log(error);
      console.log(error.response);
    }
  }, [
    email,
    password,
    navigate,
    api,
    dispatch,
    get_private.value_AddEmail,
    get_private.value_AddPassword,
  ]);

  useEffect(() => {
    // Periksa apakah email dan password tidak kosong
    if (
      get_private.value_AddEmail !== "" &&
      get_private.value_AddPassword !== ""
    ) {
      // Panggil fungsi handleSubmit untuk melakukan login
      handleAutoSubmit();
    } else {
      setLoading1(false);
    }
  }, [
    get_private.value_AddEmail,
    get_private.value_AddPassword,
    handleAutoSubmit,
    token,
    navigate,
  ]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let data = JSON.stringify({
        email: email,
        password: password,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${api}/auth/login`,
        credentials: "include",
        Credential: true,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);
      dispatch(add_token(response.data.access_token));
      dispatch(AddEmail(email));
      dispatch(AddPassword(password));
      navigate("/datapemilih");
    } catch (error) {
      console.log(error);
      console.log(error.response);
      setAlerts("Email atau password salah, silahkan coba kembali");
      setTimeout(() => {
        setAlerts("");
      }, 2000); // menghapus pesan kesalahan setelah 5 detik
    }
  };

  useEffect(() => {
    if (token !== "") {
      navigate("/");
    }
  }, [navigate, token]);

  return loading1 ? (
    <div className="page-content d-flex align-items-center justify-content-center vh-100">
      <div className="text-center">
        <div className="spinner-border text-secondary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="wrapper mt-5">
        <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
          <div className="container-fluid">
            {/* <div className="row">
                            <div className="col-6"><Video /></div>
                            <div className="col-6"><Audio /></div>
                        </div> */}
            <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
              <div className="col mx-auto">
                <div className="card">
                  <div className="card-body">
                    <div className="login-form">
                      <div className="section">
                        <img
                          src="assets/img/sample/avatar/avatar1.jpg"
                          width={60}
                          height={60}
                          alt="img"
                        />
                      </div>
                      <div className="section mt-1">
                        <h1 className="text-danger">PRB</h1>
                        <h4 className="text-danger">Pejuang Rakyat Bitung</h4>
                      </div>
                      <div className="section mt-3">
                        <h4 className="text-danger">Silahkan Masuk</h4>
                      </div>
                      {alerts && (
                        <div className="alert alert-danger mb-4" role="alert">
                          {alerts}
                        </div>
                      )}

                      <form onSubmit={handleSubmit}>
                        <div className="formgrouplogin">
                          <div className="inputanpassword">
                            <input
                              value={email}
                              onChange={(event) => setEmail(event.target.value)}
                              type="email"
                              className="classinput"
                              id="email1"
                              placeholder="Masukan alamat Email"
                            />
                          </div>
                        </div>
                        <div className="formgrouplogin">
                          <div className="inputanpassword">
                            <input
                              value={password}
                              onChange={(event) =>
                                setPassword(event.target.value)
                              }
                              type={showPassword ? "text" : "password"}
                              className="classinput"
                              id="password1"
                              placeholder="Masukan Password"
                            />
                            <div
                              onClick={togglePasswordVisibility}
                              className="eye-icon"
                            >
                              <i
                                className={
                                  iconPassword ? "bx bx-show" : "bx bx-hide"
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-links">
                          <div>
                            <Link to="/tambahuser" className="text-danger">
                              Belum punya akun?
                            </Link>
                          </div>
                          <div>
                            <Link to="/lupapass" className="text-danger">
                              Lupa Password?
                            </Link>
                          </div>
                        </div>
                        <div className="form-group mt-3">
                          <button
                            type="submit"
                            className="col-12 btn btn-danger"
                          >
                            Masuk
                          </button>
                        </div>
                        <Link to="/" className="form-group mt-1">
                          <button type="submit" className="col-12 btn btn-info">
                            Daftar Hadir
                          </button>
                        </Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
