import { Outlet } from 'react-router-dom';

const Main = () => {

    return (
        <Outlet />
    )
}

export default Main
