import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { api } from "../../api";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { add_token } from "../../../Redux/Action/Tokenaction";

function PenggunaAdd() {
  const api = process.env.REACT_APP_BASE_URL;
  const get_token = useSelector((state) => state.TokenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = get_token.value_token;
  const [nama_pengguna, setNama_Pengguna] = useState("");
  const [email_pengguna, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [retryPassword, setRetryPassword] = useState("");
  const [no_hp, setNo_hp] = useState("");
  const [errorName, setErorrName] = useState(false);
  const [errorMessage, setErorMessage] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let data = JSON.stringify({
        name: nama_pengguna,
        email: email_pengguna,
        username: username,
        password: password,
        retryPassword: retryPassword,
        level: "Operator",
        no_hp: no_hp,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${api}/auth/register`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };
      const response = await axios.request(config);
      console.log(response.data);
      Swal.fire({
        icon: "success",
        text: "Data berhasil disimpan",
        title: "success",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        navigate("/login");
      });
    } catch (error) {
      console.log(error);
      setErorrName(error.response.data.error.name);
      setErorMessage(error.response.data.error.message);
      if (error.response.data.message === "Unauthenticated.") {
        dispatch(add_token(""));
        navigate("/login");
      }
    }
  };

  return (
    <>
      <div className="wrapper mt-5">
        <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
          <div className="col mx-auto">
            <div className="d-flex justify-content-center">
              <div className="card col-lg-6 col-md-12">
                <div className="card-header d-flex justify-content-between">
                  <h3>Form Tambah Anggota</h3>
                  <Link to="/pengguna">
                    {" "}
                    <h5 title="kembali">x</h5>
                  </Link>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <form className="row g-3" onSubmit={handleSubmit}>
                        <div className="form-group col-md-6">
                          <label className="form-label">Nama Lengkap</label>
                          <input
                            onChange={(event) =>
                              setNama_Pengguna(event.target.value)
                            }
                            type="text"
                            className="form-control"
                          />
                          {errorName === "name" && (
                            <div
                              className="alert alert-danger mt-1"
                              role="alert"
                            >
                              {errorMessage} !!!
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label className="form-label">Email</label>
                          <input
                            onChange={(event) => setEmail(event.target.value)}
                            type="email"
                            className="form-control"
                          />
                          {errorName === "email" && (
                            <div
                              className="alert alert-danger mt-1"
                              role="alert"
                            >
                              {errorMessage} !!!
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label className="form-label">Username</label>
                          <input
                            onChange={(event) =>
                              setUsername(event.target.value)
                            }
                            type="text"
                            className="form-control"
                          />
                          {errorName === "username" && (
                            <div
                              className="alert alert-danger mt-1"
                              role="alert"
                            >
                              {errorMessage} !!!
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label className="form-label">Password</label>
                          <input
                            onChange={(event) =>
                              setPassword(event.target.value)
                            }
                            type="password"
                            className="form-control"
                          />
                          {errorName === "password" && (
                            <div
                              className="alert alert-danger mt-1"
                              role="alert"
                            >
                              {errorMessage} !!!
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label className="form-label">
                            Konfirmasi Password
                          </label>
                          <input
                            onChange={(event) =>
                              setRetryPassword(event.target.value)
                            }
                            type="password"
                            className="form-control"
                          />
                          {errorName === "retryPassword" && (
                            <div
                              className="alert alert-danger mt-1"
                              role="alert"
                            >
                              {errorMessage} !!!
                            </div>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label className="form-label">Kontak</label>
                          <input
                            onChange={(event) => setNo_hp(event.target.value)}
                            type="number"
                            className="form-control"
                          />
                          {errorName === "no_hp" && (
                            <div
                              className="alert alert-danger mt-1"
                              role="alert"
                            >
                              {errorMessage} !!!
                            </div>
                          )}
                        </div>
                        <div className="form-group col-12">
                          <div className="modal-footer">
                            <Link
                              to="/pengguna"
                              type="button"
                              className="btn btn-danger"
                            >
                              Batal
                            </Link>
                            <button type="submit" className="btn btn-success">
                              Simpan
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PenggunaAdd;
