import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { add_token } from "../../../Redux/Action/Tokenaction";

const TambahKecamatan = () => {
  const api = process.env.REACT_APP_BASE_URL;
  const get_token = useSelector((state) => state.TokenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = get_token.value_token;
  const [kecamatan, setKecamatan] = useState("");
  const [errorName, setErorrName] = useState(false);
  const [errorMessage, setErorMessage] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let data = JSON.stringify({
        kecamatan: kecamatan,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${api}/kecamatan`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      const response = await axios.request(config);
      console.log(response.data);
      Swal.fire({
        icon: "success",
        text: "Data berhasil disimpan",
        title: "success",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        navigate("/kecamatan");
      });
    } catch (error) {
      console.log(error.response);
      setErorrName(error.response.data.error.name);
      setErorMessage(error.response.data.error.message);
      if (error.response.data.message === "Unauthenticated.") {
        dispatch(add_token(""));
        navigate("/login");
      }
    }
  };

  return (
    <div>
      <div className="appHeader bg-danger text-light">
        <div className="left">
          <Link to="/kecamatan" className="headerButton goBack">
            <ion-icon name="chevron-back-outline" />
          </Link>
        </div>
        <div className="pageTitle">Form Tambah Kecamatan</div>
        <div className="right" />
      </div>
      <div id="appCapsule">
        <div className="section full mb-2">
          <div className="section-title">Tambah Kecamatan</div>
          <div className="pt-2 pb-2">
            <form
              className="needs-validation col-md-4 mx-auto"
              noValidate
              onSubmit={handleSubmit}
            >
              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label mt-5" htmlFor="kecamatan">
                    <h3 className="text-muted">Name Kecamatan</h3>
                  </label>
                  <input
                    onChange={(event) => setKecamatan(event.target.value)}
                    type="text"
                    className="form-control"
                    id="name5"
                    placeholder="Masukan nama Kecamatan"
                  />
                  {errorName === "kecamatan" ? (
                    <div className="text-danger">{errorMessage}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="mt-2">
                <button
                  className="btn btn-outline-primary btn-block"
                  type="submit"
                >
                  Simpan
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TambahKecamatan;
