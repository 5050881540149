export const add_ID = (id) => {
  return {
    type: "NILAI_ID",
    payload: id,
  };
};

export const add_id_pemilih = (id_pemilih) => {
  return {
    type: "NILAI_ID_PEMILIH",
    payload: id_pemilih,
  };
};

export const add_id_pengguna = (id_pengguna) => {
  return {
    type: "NILAI_ID_PENGGUNA",
    payload: id_pengguna,
  };
};


export const add_tema = (id_tema) => {
  return {
    type: "NILAI_ID_TEMA",
    payload: id_tema,
  };
};

export const add_id_kecamatan = (id_kecamatan) => {
  return {
    type: "NILAI_ID_KECAMATAN",
    payload: id_kecamatan,
  };
}

export const add_id_kelurahan = (id_kelurahan) => {
  return {
    type: "NILAI_ID_KELURAHAN",
    payload: id_kelurahan,
  };
};


