import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
// import { api } from "../../api";
import moment from "moment";
import "moment/locale/id"; // import locale bahasa Indonesia

import { useDispatch, useSelector } from "react-redux";
import { add_token } from "../../../Redux/Action/Tokenaction";
import { add_id_pengguna } from "../../../Redux/Action/Idaction";
import { add_link_pengguna } from "../../../Redux/Action/LinkAction";
import { AddEmail, AddPassword } from "../../../Redux/Action/PrivateAction";
import {
  addIdUser,
  addNamaUser,
  addUsernameUser,
  addlevelUser,
} from "../../../Redux/Action/UserAction";

function formatDate(date) {
  return moment(date).locale("id").format("DD/MM/YYYY hh:mm:ss");
}

function Profile() {
  const api = process.env.REACT_APP_BASE_URL;
  const get_token = useSelector((state) => state.TokenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = get_token.value_token;
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [daftar, setDaftar] = useState("");
  const [lastUpdate, setLastupdate] = useState("");
  const [level, setLevel] = useState("");
  const [no_telpon, setNoTelpon] = useState("");
  const [id, setId] = useState("");
  const [loading1, setLoading1] = useState(false);

  useEffect(() => {
    setLoading1(true);
    const fetchdata = async () => {
      try {
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${api}/auth/me`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.request(config);
        setLoading1(false);
        setId(response.data.id);
        setName(response.data.name);
        setUsername(response.data.username);
        setEmail(response.data.email);
        setLevel(response.data.level);
        setNoTelpon(response.data.no_hp);
        setDaftar(response.data.created_at);
        setLastupdate(response.data.updated_at);
        dispatch(addIdUser(response.data.id));
        dispatch(addNamaUser(response.data.name));
        dispatch(addUsernameUser(response.data.username));
        dispatch(addlevelUser(response.data.level));
      } catch (error) {
        console.log(error);
        if (error.response.data.message === "Unauthenticated.") {
          dispatch(add_token(""));
          navigate("/login");
        }
      }
    };
    fetchdata();
  }, [token, navigate, dispatch, api]);

  const handleLogout = async () => {
    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${api}/auth/logout`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.request(config);

      if (response.status === 200) {
        dispatch(add_token(""));
        dispatch(AddEmail(""));
        dispatch(AddPassword(""));
        dispatch(addIdUser(0));
        dispatch(addNamaUser(""));
        dispatch(addUsernameUser(""));
        dispatch(addlevelUser(""));

        navigate("/login");
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.message === "Unauthenticated.") {
        dispatch(add_token(""));
        navigate("/login");
      }

      navigate("/login");
    }
  };

  return (
    <>
      <div className="appHeader bg-danger text-light">
        <div className="left">
          <Link to="/" className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </Link>
        </div>
        <div className="pageTitle">Profile</div>
      </div>

      <div className="wrapper mt-3 pt-5">
        <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
          <div className="container-fluid">
            <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
              <div className="col mx-auto">
                <div className="card">
                  <div className="card-body">
                    {loading1 ? (
                      <div className="text-center">
                        <div
                          className="spinner-border m-5 text-danger"
                          role="status"
                        >
                          <span className="visually-hidden"></span>
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="text-center user-info col-md-12">
                          <div className="card-body">
                            <img
                              src="assets/img/sample/avatar/avatar1.jpg"
                              alt="avatar"
                              className="rounded-circle mb-1"
                              style={{ width: 100 }}
                            />
                            <br />
                            Selamat datang
                            <br />
                            {username}
                          </div>
                        </div>
                        <div className="user-info-list col-md-12">
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item bg-transparent">
                              <i className="bx bx-at font-18 align-middle me-1" />{" "}
                              {email}
                            </li>
                            <li className="list-group-item bg-transparent">
                              <i className="bx bx-group font-18 align-middle me-1" />{" "}
                              {name}
                            </li>
                            <li className="list-group-item bg-transparent">
                              <i className="bx bx-phone font-18 align-middle me-1" />{" "}
                              {"0" + no_telpon}
                            </li>
                            <li className="list-group-item bg-transparent">
                              <i className="bx bx-layer font-18 align-middle me-1" />{" "}
                              {level}
                            </li>
                          </ul>
                        </div>
                        <div className="user-info-list col-md-12">
                          <div className="card bg-secondary  login-page border-0">
                            <div className="p-3 text-white">
                              <ul
                                className="contacts-block list-unstyled "
                                style={{ maxWidth: "800px" }}
                              >
                                <li className="list-group-item bg-transparent text-white">
                                  <i className="bx bx-calendar-check font-18 align-middle me-1" />
                                  Tanggal Daftar : {formatDate(daftar)}
                                </li>
                                <li className="list-group-item bg-transparent text-white">
                                  <i className="bx bx-log-in font-18 align-middle me-1" />{" "}
                                  Update Terakhir : {formatDate(lastUpdate)}
                                </li>
                              </ul>
                              <button
                                onClick={() => {
                                  navigate("/editprofile");
                                  dispatch(add_id_pengguna(id));
                                  dispatch(add_link_pengguna("/profile"));
                                }}
                                className="btn btn-sm btn-outline-light float-left"
                              >
                                Ubah Profile
                              </button>
                              <button
                                onClick={() => {
                                  navigate("/resetpass");
                                  dispatch(add_id_pengguna(id));
                                }}
                                className="btn btn-sm btn-outline-light float-right"
                              >
                                {" "}
                                Ganti Password{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="user-info-list col-md-12">
                          <ul className="list-group list-group-flush">
                            <li
                              className="btn btn-danger mt-1"
                              style={{ cursor: "pointer" }}
                              onClick={handleLogout}
                            >
                              <i className="bx bx-power-off font-18 align-middle me-1" />
                              Logout
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
