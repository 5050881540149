import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { add_token } from "../../../Redux/Action/Tokenaction";
import { add_id_kelurahan } from "../../../Redux/Action/Idaction";

const DetailKecamatan = () => {
  const api = process.env.REACT_APP_BASE_URL;
  const get_token = useSelector((state) => state.TokenReducer);
  const get_id_kecamatan = useSelector((state) => state.IdValueReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = get_token.value_token;
  const [loading1, setLoading1] = useState(false);
  const [dataKecamatan, setDataKecamatan] = useState("");
  const [dataKelurahan, setDataKelurahan] = useState("");

  useEffect(() => {
    setLoading1(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/kecamatan/${get_id_kecamatan.value_id_kecamatan}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLoading1(false);
        console.log(response.data);
        setDataKecamatan(response.data.kecamatan[0]);
        setDataKelurahan(response.data.kelurahan);
      } catch (error) {
        console.log(error);
        if (error.response.data.message === "Unauthenticated.") {
          dispatch(add_token(""));
          navigate("/login");
        }
      }
    };
    fetchData();
  }, [token, navigate, dispatch, api, get_id_kecamatan.value_id_kecamatan]);

  const color = [
    "bg-primary",
    "bg-secondary",
    "bg-warning",
    "bg-danger",
    "bg-success",
    "bg-info",
    "bg-orange",
    "bg-pink",
    "bg-purple",
    "bg-green",
    "bg-yellow",
    "bg-cyan",
    "bg-brown",
    "bg-gold",
    "bg-lavender",
    "bg-magenta",
    "bg-teal",
  ];

  return (
    <div>
      {loading1 ? (
        <div id="loader">
          <div className="spinner-border text-danger" role="status"></div>
        </div>
      ) : (
        <>
          <div className="appHeader bg-danger text-light">
            <div className="left">
              <Link to="/kecamatan" className="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
              </Link>
            </div>
            <div className="pageTitle">
              List Kelurahan di Kec. {dataKecamatan.kecamatan}
            </div>
          </div>

          <div id="appCapsule">
            <div className="listview-title mt-2">List Data Kecamatan</div>
            <div className="listview-title pt-2 pb-0">
              Jumlah Pemilih Kec. {dataKecamatan.kecamatan}
            </div>
            <div className="presencetab pl-1 pr-1">
              <div className="row">
                <div className="col-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="presencecontent text-center">
                        {/* <div className="iconpresence green">
                                                    <ion-icon name="document-text" />
                                                </div> */}
                        <div className="presencedetail">
                          <h4 className="rekappresencetitle">Total</h4>
                          <div className="rekappresencedetail">
                            <span className="badge badge-info">
                              {dataKecamatan.c_pemilih_count}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="presencecontent text-center">
                        {/* <div className="iconpresence warning">
                                                    <ion-icon name="document-text" />
                                                </div> */}
                        <div className="presencedetail">
                          <h4 className="rekappresencetitle">Pasti</h4>
                          <div className="rekappresencedetail">
                            <span className="badge badge-danger">
                              {dataKecamatan.c_pemilih_pasti_count}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="presencecontent">
                        {/* <div className="iconpresence danger">
                                                    <ion-icon name="document-text" />
                                                </div> */}
                        <div className="presencedetail text-center">
                          <h4 className="rekappresencetitle">Belum</h4>
                          <div className="rekappresencedetail">
                            <span className="badge badge-warning">
                              {dataKecamatan.c_pemilih_belum_pasti_count}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="listview-title pt-2 pb-0">
                Jumlah Pemilih Perkelurahan
              </div>
              <ul className="listview image-listview">
                {dataKelurahan &&
                  dataKelurahan.map((kelurahan, index) => (
                    <li key={kelurahan.id}>
                      <div className="item">
                        <div
                          className={`icon-box ${color[index % color.length]}`}
                        >
                          <ion-icon name="location" />
                        </div>
                        <div className="in">
                          <div>
                            {kelurahan.kelurahan}
                            <header>
                              <span className="badge badge-info">
                                Total : {kelurahan.total_count}
                              </span>
                            </header>
                            <footer>
                              <span className="badge badge-danger">
                                {kelurahan.sudah_pasti_count}
                              </span>
                              <span className="badge badge-warning">
                                {kelurahan.belum_pasti_count}
                              </span>
                            </footer>
                          </div>
                          <Link
                            to="/kelurahan"
                            onClick={() => {
                              dispatch(add_id_kelurahan(kelurahan.id));
                            }}
                            className="text-dark"
                          >
                            Details
                          </Link>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DetailKecamatan;
