import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { api } from "../components/api";
import Swal from "sweetalert2";

const ResetPassword = () => {
  const api = process.env.REACT_APP_BASE_URL;

  // Mendapatkan URL saat ini
  const currentUrl = window.location.href;

  // Mendapatkan query string dari URL
  const queryString = currentUrl.split("?")[1];

  // Membuat objek dari query string
  const params = new URLSearchParams(queryString);

  // Mengambil nilai token dari parameter URL
  const token = params.get("token");

  const navigate = useNavigate();
  // const token = 'bf41c62396e9b413f4c30e9c3617fe18c7cb52d6e9aaca1005b3deb88c2d0d97';
  const [email, setEmail] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [retryPassword, setRetryPassword] = useState("");
  const [errorName, setErorrName] = useState(false);
  const [errorMessage, setErorMessage] = useState(false);

  const handleSubmit = async (event) => {
    const FormData = require("form-data");
    let data = new FormData();
    data.append("token", token);
    data.append("email", email);
    data.append("password", newPassword);
    data.append("password_confirmation", retryPassword);
    event.preventDefault();
    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${api}/auth/reset-password`,
        data: data,
      };
      const response = await axios.request(config);
      console.log(response);
      Swal.fire({
        icon: "success",
        text: "Password berhasil direset",
        title: "success",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        navigate("/login");
      });
    } catch (error) {
      console.log(error);
      setErorrName(error.response.data.errors);
      setErorMessage(error.response.data.message);
      if (error.response.status === 400) {
        setErorMessage(error.response.data.email);
      }
    }
  };

  return (
    <div className="wrapper pt-4">
      <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-4 col-xl-4 mx-auto">
              <div className="card">
                <div className="card-header">
                  <h5 className="mt-1">Form Reset Password</h5>
                </div>
                <div className="row g-0">
                  <div className="card-body">
                    {errorMessage !== false ? (
                      <div className="alert alert-danger" role="alert">
                        {errorMessage}
                      </div>
                    ) : (
                      ""
                    )}
                    <form onSubmit={handleSubmit}>
                      <div className="col-12">
                        <label
                          htmlFor="inputChoosePassword"
                          className="form-label"
                        >
                          Alamat Email
                        </label>
                        <div className="input-group" id="show_hide_password">
                          <input
                            onChange={(event) => setEmail(event.target.value)}
                            id="email"
                            name="email"
                            type="email"
                            className="form-control border-start-0 border-end-0"
                            placeholder="Masukan alamat email"
                          />
                        </div>
                        {errorName === "password_lama" && (
                          <div className="alert alert-danger mt-1" role="alert">
                            {errorMessage} !!!
                          </div>
                        )}
                      </div>
                      <div className="col-12 mt-3">
                        <label
                          htmlFor="inputChoosePassword"
                          className="form-label"
                        >
                          Password Baru
                        </label>
                        <div className="input-group" id="show_hide_password">
                          <input
                            onChange={(event) =>
                              setNewPassword(event.target.value)
                            }
                            id="password_baru"
                            name="password_baru"
                            type="password"
                            className="form-control border-start-0 border-end-0"
                            placeholder="Masukan Password Baru"
                          />

                          {errorName === "password_baru" && (
                            <div
                              className="alert alert-danger mt-1"
                              role="alert"
                            >
                              {errorMessage} !!!
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <label
                          htmlFor="inputChoosePassword"
                          className="form-label"
                        >
                          Konfirmasi Password Baru
                        </label>
                        <div className="input-group" id="show_hide_password">
                          <input
                            onChange={(event) =>
                              setRetryPassword(event.target.value)
                            }
                            id="password_konfirmasi"
                            name="password_konfirmasi"
                            type="password"
                            className="form-control border-start-0 border-end-0"
                            placeholder="Konfirmasi Password Baru"
                          />

                          {errorName === "password_konfirmasi" && (
                            <div
                              className="alert alert-danger mt-1"
                              role="alert"
                            >
                              {errorMessage} !!!
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-links col-12 mt-3">
                        <Link to="/login" className="btn btn-secondary">
                          Kembali
                        </Link>
                        <button type="submit" className="btn btn-danger">
                          Reset Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
