export const add_link_pemilih = (link_pemilih) => {
    return {
        type: "NILAI_LINK_PEMILIH",
        payload: link_pemilih,
    };
};


export const add_link_pengguna = (link_pengguna) => {
    return {
        type: "NILAI_LINK_PENGGUNA",
        payload: link_pengguna,
    };
};

export const add_tema = (model_tema) => {
    return {
        type: "NILAI_TEMA",
        payload: model_tema,
    };
};