import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
// import Swal from 'sweetalert2';
import { useDispatch, useSelector } from "react-redux";
import { add_token } from "../../../Redux/Action/Tokenaction";
import { add_id_pengguna } from "../../../Redux/Action/Idaction";
// import { add_link_pengguna } from "../../../Redux/Action/LinkAction";

const Progress = () => {
  const api = process.env.REACT_APP_BASE_URL;
  const get_token = useSelector((state) => state.TokenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = get_token.value_token;
  const [PenggunaData, setPenggunaData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState([]);
  // const [count, setCount] = useState(0);
  const [loading1, setLoading1] = useState(false);

  //pengaturan pagination
  let pages = [];
  if (totalPage <= 3) {
    pages = Array.from({ length: totalPage }, (_, index) => index + 1);
  } else if (currentPage <= 2) {
    pages = [1, 2, 3];
  } else if (currentPage >= totalPage - 1) {
    pages = [totalPage - 2, totalPage - 1, totalPage];
  } else {
    pages = [currentPage - 1, currentPage, currentPage + 1];
  }

  const fetchSearchPengguna = async (search) => {
    try {
      const response = await axios.get(
        `${api}/pengguna/search?query=${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPenggunaData(response.data.data);
      setTotalData(response.data.data.total);
      setTotalPage(response.data.data.last_page);
      setCurrentPage(response.data.data.current_page);
    } catch (error) {
      console.log(error);
      if (error.response.data.message === "Unauthenticated.") {
        dispatch(add_token(""));
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    setLoading1(true);
    const fetchdata = async () => {
      try {
        const response = await axios.get(
          `${api}/pengguna?page=${currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLoading1(false);
        // console.log(response.data)
        setPenggunaData(response.data.data);
        setTotalData(response.data.data.total);
        setTotalPage(response.data.data.last_page);
        setCurrentPage(response.data.data.current_page);
      } catch (error) {
        console.log(error);
        if (error.response.data.message === "Unauthenticated.") {
          dispatch(add_token(""));
          navigate("/login");
        } else {
          navigate("/error");
        }
      }
    };
    fetchdata();
  }, [token, currentPage, navigate, dispatch, api]);

  const color = [
    "bg-primary",
    "bg-secondary",
    "bg-warning",
    "bg-danger",
    "bg-success",
    "bg-info",
    "bg-orange",
    "bg-pink",
    "bg-purple",
    "bg-green",
    "bg-yellow",
    "bg-cyan",
    "bg-brown",
    "bg-gold",
    "bg-lavender",
    "bg-magenta",
    "bg-teal",
  ];

  return (
    <>
      {loading1 ? (
        <div className="text-center mt-5">
          <div className="spinner-border text-danger" role="status"></div>
        </div>
      ) : (
        <>
          <ul className="listview simple-listview">
            <li>
              <div className="search-form">
                <div className="form-group searchbox">
                  <input
                    onChange={(event) => {
                      setSearch(event.target.value);
                    }}
                    type="text"
                    className="form-control"
                    placeholder="Cari nama "
                  />

                  <i className="input-icon">
                    <ion-icon name="search-outline" />
                  </i>
                  <div
                    className="ml-1"
                    onClick={() => {
                      fetchSearchPengguna(search);
                    }}
                    cursor={{ cursor: "pointer" }}
                  >
                    <ion-icon name="search-outline" />
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div className="listview-title pb-0" style={{ fontSize: "14px" }}>
            Jumlah Anggota : {totalData}
          </div>
          <div className="listview-title pb-0" style={{ fontSize: "14px" }}>
            Progress Anggota
          </div>
          <ul className="listview image-listview">
            {PenggunaData.data &&
              PenggunaData.data.map((PenggunaData, index) => (
                <li key={PenggunaData.id}>
                  <div className="item">
                    {/* <img src="assets/img/sample/avatar/avatar1.jpg" alt="users" className="image" /> */}
                    <div className={`icon-box ${color[index % color.length]}`}>
                      {PenggunaData.name.charAt(0)}
                    </div>
                    <Link
                      to="/datapemilihpengguna"
                      className="in"
                      onClick={() => {
                        dispatch(add_id_pengguna(PenggunaData.id));
                      }}
                    >
                      <div>{PenggunaData.name} </div>
                      <div>
                        <span className="badge badge-info">
                          {PenggunaData.total_count}
                        </span>
                      </div>
                    </Link>
                  </div>
                </li>
              ))}
          </ul>
        </>
      )}

      <ul className="pagination justify-content-center pb-3 pt-3">
        <ul className="pagination">
          {currentPage > 2 && (
            <li className="page-item">
              <button className="page-link" onClick={() => setCurrentPage(1)}>
                First
              </button>
            </li>
          )}
          {currentPage > 2 && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                «
              </button>
            </li>
          )}
          {pages.map((page) => (
            <li
              key={page}
              className={
                page === currentPage ? "page-item active" : "page-item"
              }
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage(page)}
              >
                {page}
              </button>
            </li>
          ))}
          {currentPage < totalPage && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                »
              </button>
            </li>
          )}
          {currentPage < totalPage && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => setCurrentPage(totalPage)}
              >
                Last
              </button>
            </li>
          )}
        </ul>
      </ul>
    </>
  );
};

export default Progress;
