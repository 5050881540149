const data_private = {
  value_AddEmail: "",
  value_AddPassword: "",
};

const PrivateReducer = (state = data_private, action) => {
  switch (action.type) {
    case "NILAI_EMAIL":
      return {
        ...state,
        value_AddEmail: action.payload,
      };
    case "NILAI_PASSWORD":
      return {
        ...state,
        value_AddPassword: action.payload,
      };

    default:
      return state;
  }
};

export default PrivateReducer;
