const data_user = {
  value_AddIdUser: 0,
  value_AddNamaUser: "",
  value_AddUsernameUser: "",
  value_AddlevelUser: "",
};

const UserReducer = (state = data_user, action) => {
  switch (action.type) {
    case "NILAI_ID_USER":
      return {
        ...state,
        value_AddIdUser: action.payload,
      };
    case "NILAI_NAMA_USER":
      return {
        ...state,
        value_AddNamaUser: action.payload,
      };
    case "NILAI_USERNAME_USER":
      return {
        ...state,
        value_AddUsernameUser: action.payload,
      };
    case "NILAI_LEVEL_USER":
      return {
        ...state,
        value_AddlevelUser: action.payload,
      };
    default:
      return state;
  }
};

export default UserReducer;
