// Tanpa storage
// import { combineReducers, createStore } from "redux";

// import IniSireducer from "./../Reducer/IniSireducer";
// import VotingReducer from "../Reducer/Votingreducer";
// import IdValueReducer from "../Reducer/IdReducer"
// import TokenReducer from '../Reducer/TokenReducer'

// const rootReducer = combineReducers({
//   IniSireducer,
//   VotingReducer,
//   IdValueReducer,
//   TokenReducer
// });

// const store = createStore(rootReducer);

// export default store;

// pakai storage
import { combineReducers, legacy_createStore as createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import IniSireducer from "../Reducer/IniSireducer";
import VotingReducer from "../Reducer/Votingreducer";
import IdValueReducer from "../Reducer/IdReducer";
import TokenReducer from "../Reducer/TokenReducer";
import LinkValueReducer from "../Reducer/Linkreducer";
import UserReducer from "../Reducer/UserResucer";
import PrivateReducer from "../Reducer/PrivateReducer";

const rootReducer = combineReducers({
  IniSireducer,
  VotingReducer,
  IdValueReducer,
  TokenReducer,
  LinkValueReducer,
  UserReducer,
  PrivateReducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "IdValueReducer",
    "TokenReducer",
    "LinkValueReducer",
    "UserReducer",
    "PrivateReducer",
  ], // Menyimpan data ke storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);
const persistor = persistStore(store);

export { store, persistor };
